import { Box, Dialog, Snackbar, Link } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useState, useEffect } from 'react'
import { NavLink } from "react-router-dom";

import './header.scss';
import Logo from '../../assets/images/logo.png'
import toLeft from '../../assets/images/toLeft.png'
import toRight from '../../assets/images/toRight.png'
import Square from '../../assets/images/square.png'
import Menu from '../../assets/images/menu.png'
import Close from '../../assets/images/close.png'

export default function Header() {

    const isSmallScreen = useMediaQuery("(max-width: 1020px)");
    const [open, setOpen] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseMenu = () => {
        setOpenMenu(false);
    };

    useEffect(() => {
        let timer
        if (open) {
            timer = setInterval(() => {
                setOpen(false)
            }, 3000)
        }

        return () => {
            clearInterval(timer)
        }
    }, [open])

    return (
        <div className='header-view'>
            {
                !isSmallScreen &&
                <div className="footer">
                    <Box display='flex' alignItems='center' justifyContent='flex-end' className="footer-t">
                        <img className="toLeft" src={toLeft} />
                    </Box>
                    <Box display='flex' alignItems='center' justifyContent='flex-start' className="footer-b">
                        <img className="toRight" src={toRight} />
                    </Box>
                </div>
            }
            <Box display='flex' justifyContent='center'>
                <div className="container">
                    {
                        isSmallScreen ?
                            <Box display='flex' justifyContent='space-between' alignItems='center' className="header-m font-14">
                                <img src={Menu} className="" onClick={() => { setOpenMenu(true) }} />
                                <Box display='flex' alignItems='center' className="header-r">
                                    <Link component={NavLink} to="/">
                                        <img className="logo" src={Logo} />
                                    </Link>
                                    <a href="https://app.ticketinu.com/" target='_blank'>
                                        <Box display='flex' justifyContent='center' alignItems='center' className="enterApp ml-18 color1 font-family-Futura-Bold border-radius-4"
                                        >Enter APP</Box>
                                    </a>
                                </Box>
                            </Box>
                            :
                            <Box display='flex' justifyContent='space-between' alignItems='center' className="header font-16">
                                 <Link component={NavLink} to="/">
                                <img className="" src={Logo} />
                                </Link>
                                <Box display='flex' className="menuList">
                                    <Link component={NavLink} to="/" className="menuItem">
                                        <div className="color2">Home</div>
                                    </Link>
                                    <a href="https://docs.ticketinu.com/" target='_blank' className="menuItem">
                                        <div className="color2">Docs</div>
                                    </a>
                                    <a href="https://discord.gg/GHN7ESkXxU" target='_blank' className="menuItem">
                                        <div className="color2">Discord</div>
                                    </a>
                                    <a href="https://www.pinksale.finance/#/launchpad/0xD2D67dF01bCd1e3f9D7F0ACe9c0105EBf214776E?chain=ETH" target='_blank' className="menuItem">
                                        <Box display='flex' alignItems='center' className="ido color3">
                                            <div className="circular"></div>
                                            <div className="idoDesc">IDO</div>
                                            <div className="circular"></div>
                                        </Box>
                                    </a>
                                </Box>
                                <a href="https://app.ticketinu.com/" target='_blank'>
                                    <Box display='flex' justifyContent='center' alignItems='center' className="enterApp text-center color1 font-family-Futura-Bold border-radius-4"
                                    >Enter APP</Box>
                                </a>
                            </Box>
                    }
                    <Box display='flex' justifyContent='center' className="title font-48 color3 font-family-Futura-Bold">WIN A LOTTERY</Box>
                    <Box display='flex' justifyContent='center' className="mt-8 font-24">WHILE</Box>
                    <Box display='flex' justifyContent='center' className="title2 mt-12 font-48 font-family-Futura-Bold">AUTO-STAKING&COMPOUNDING</Box>
                    <Box display='flex' justifyContent='center' className="descList mt-30 color4">
                        <Box display='flex' flexDirection='column'>
                            <Box display='flex' alignItems='center' className="">
                                <img src={Square} />
                                <div className="ml-10">Highest Fixed APY in Crypto – 422,511.01%</div>
                            </Box>
                            <Box display='flex' alignItems='center' className="mt-8">
                                <img src={Square} />
                                <div className="ml-10">Low Risk with TICKET Insurance Fund (TIF)</div>
                            </Box>
                            <Box display='flex' alignItems='center' className="mt-8">
                                <img src={Square} />
                                <div className="ml-10">Win a lottery for every TICKET you purchase!</div>
                            </Box>
                        </Box>
                        <Box display='flex' flexDirection='column' className="descItem-r">
                            <Box display='flex' alignItems='center' className="">
                                <img src={Square} />
                                <div className="ml-10">Interest Paid Every 15 Minutes: 96 Times Daily!</div>
                            </Box>
                            <Box display='flex' alignItems='center' className="mt-8">
                                <img src={Square} />
                                <div className="ml-10">Automatic Staking and Compounding in Your Wallet!</div>
                            </Box>
                        </Box>
                    </Box>
                </div>
            </Box>
            <Dialog
                fullScreen
                open={openMenu}
                onClose={handleCloseMenu} >
                <div className='menu-view font-16'>
                    <Box display='flex' flexDirection='column' alignItems='center'>
                        <Link component={NavLink} to="/" onClick={handleCloseMenu}>
                            <img src={Logo} className="mt-18" />
                        </Link>
                        <div className="menuList">
                            <Link component={NavLink} to="/" className="menuItem" onClick={handleCloseMenu}>
                                <Box display='flex' alignItems='center' justifyContent='center' className="color2">Home</Box>
                            </Link>
                            <a href="https://docs.ticketinu.com/" target='_blank' className="menuItem mt-20">
                                <Box display='flex' alignItems='center' justifyContent='center' className="color2">Docs</Box>
                            </a>
                            <a href="https://discord.gg/GHN7ESkXxU" target='_blank' className="menuItem mt-20">
                                <Box display='flex' alignItems='center' justifyContent='center' className="color2">Discord</Box>
                            </a>
                            <a href="https://www.pinksale.finance/#/launchpad/0xD2D67dF01bCd1e3f9D7F0ACe9c0105EBf214776E?chain=ETH" target='_blank' className="menuItem mt-20">
                                <Box display='flex' alignItems='center' justifyContent='center' className="color2">IDO</Box>
                            </a>
                        </div>
                    </Box>
                    <img className="close" src={Close} onClick={handleCloseMenu} />
                </div>
            </Dialog>
            <Snackbar open={open} autoHideDuration={1000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Alert variant="filled" icon={false} onClose={handleClose}>
                    coming soon...
                </Alert>
            </Snackbar>
        </div>
    )
}