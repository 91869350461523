import { Box } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import './footer.scss';
import Logo from '../../assets/images/logo2.png'
import Footer1 from '../../assets/images/footer1.png'
import Footer2 from '../../assets/images/footer2.png'
import Footer3 from '../../assets/images/footer3.png'
import Footer4 from '../../assets/images/footer4.png'
import Footer5 from '../../assets/images/footer5.png'

export default function Footer() {

    const isSmallScreen = useMediaQuery("(max-width: 1020px)");

    return (
        <div className='footer-view'>
            <Box display='flex' justifyContent='center'>
                <Box display='flex' justifyContent='center' className="footer">
                    <Box display='flex' justifyContent='space-between' alignItems='center' className="container">
                        <img src={Logo} className="logo" />
                        <Box display='flex' className="footer-r">
                            <a href="https://twitter.com/TicketInu" target="_blank">
                                <img src={Footer1} />
                            </a>
                            <a href="https://discord.gg/GHN7ESkXxU" target="_blank">
                                <img src={Footer2} className='ml-30' />
                            </a>
                            <a href="https://www.reddit.com/user/TicketInu" target="_blank">
                                <img src={Footer3} className='ml-30' />
                            </a>
                            <a href="https://medium.com/@ticketinu" target="_blank">
                                <img src={Footer4} className='ml-30' />
                            </a>
                            <a href="https://t.me/+8aEUGgRmM4tlN2Qx" target="_blank">
                                <img src={Footer5} className='ml-30' />
                            </a>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </div>
    )
}